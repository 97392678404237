import {useEffect} from 'react';
import {featureFlag} from 'app/featureFlags/featureFlags';

const cookieName = 'fupaEAF';

export default function useExtendEAFeatureAvailability(feature: featureFlag) {
  useEffect(() => {
    const eafCookie = document.cookie.split(';').find(c => {
      return c.trim().startsWith(`${cookieName}=`);
    });
    if (!eafCookie) {
      return;
    }
    const cookieValue = eafCookie.split('=')[1];
    document.cookie = `${cookieName}=${cookieValue}; path=/; expires=${feature.availableUntil.toUTCString()}; samesite=lax;`;
  }, []);
}
